const supportTeam  = 'CIO.Legal.Operations.ALICE@accenture.com';
const userGuide = 'https://kxdocuments.accenture.com/contribution/3ef4d63e-9144-42cf-84f4-f21204d2accc';
const logTicket = 'https://support.accenture.com/support_portal?id=main&spa=1&tab=home-page&catalog_sys_id=e0d08b13c3330100c8b837659bba8fb4';
export const ErrorMessage = [
    {
        error_code: '1001',
        error_title: 'Expired ESO token',
        error_type: 'warning',
        error_message: 'ESO token expired, please refresh this page.'
    }, {
        error_code: '1002',
        error_title: 'System error - Can’t connect GCP service',
        error_type: 'error',
        error_message: `Fail to connect with Google Translation service.
        You can check your network connection. 
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1003',
        error_title: 'Invalid request',
        error_type: 'error',
        error_message: `Sorry, something went wrong. Please try closing and re-opening it. 
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1004',
        error_type: 'error',
        error_title: 'System error for unknown reason',
        error_message: `Sorry, something went wrong. Please try closing and re-opening it.
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1005',
        error_type: 'error',
        error_title: 'Detection service error',
        error_message: `Sorry, something went wrong. Please try again.
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1006',
        error_type: 'error',
        error_title: 'Translate service error',
        error_message: `Sorry, something went wrong. Please try again.
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1007',
        error_type: 'error',
        error_title: 'Speech-to-text service error',
        error_message: `Sorry, something went wrong. Please try again.
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '1008',
        error_type: 'error',
        error_title: 'OCR service error',
        error_message: `Sorry, something went wrong. Please try again.
        If you still face the same issue, please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '2001',
        error_type: 'warning',
        error_title: 'File size limitation',
        error_message: `Maximum limit of file upload is 8 MB. You can split your file into smaller pieces or 
        please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }, {
        error_code: '2002',
        error_title: 'File type error',
        error_type: 'warning',
        error_message: `Only accept .txt, .docx, .pdf, .png, .jpg, .jpeg file.`
    }, {
        error_code: '2003',
        error_type: 'error',
        error_title: 'Invalid speech-to-text language',
        error_message: `Speech to text language not supported. Please choose another language.`
    }, {
        error_code: '2004',
        error_type: 'error',
        error_title: 'No words in speech-to-text',
        error_message: `No words detected from speech. Please speak again.`
    }, {
        error_code: '2005',
        error_type: 'error',
        error_title: 'No words in txt',
        error_message: `No words extracted from the txt file. Please try another one.`
    }, {
        error_code: '2006',
        error_type: 'error',
        error_title: 'Invalid docx',
        error_message: `Docx file failed to extract. Please try another one.`
    }, {
        error_code: '2007',
        error_type: 'error',
        error_title: 'No words in docx',
        error_message: `No words extracted from the docx file. Please try another one.`
    }, {
        error_code: '2008',
        error_type: 'error',
        error_title: 'Invalid PDF',
        error_message: `PDF file not extractable. Please try another one.`
    }, {
        error_code: '2009',
        error_type: 'error',
        error_title: 'No words in PDF',
        error_message: `No words extracted from the PDF file. Please try another one.`
    }, {
        error_code: '2010',
        error_type: 'error',
        error_title: 'No words in image',
        error_message: `No words detected on this image. Please try another one.`
    }, {
        error_code: '2011',
        error_type: 'warning',
        error_title: 'Invalid file type',
        error_message: `Only accept .txt, .docx, .pdf, .png, .jpg, .jpeg file.`
    }, {
        error_code: '2012',
        error_type: 'error',
        error_title: 'Over total characters limit',
        error_message: `You can split your file into smaller pieces or
         please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`,
        include_message: true
    }, {
        error_code: '2013',
        error_type: 'error',
        error_title: 'Over paragraph characters limit',
        error_message: `Each paragraph shouldn't be longer than 30,000 characters.
        File has one paragraph with too many characters.
        You can split your large paragraphs into smaller paragraphs or 
        please <a target="_blank" href="${logTicket}">log a ticket</a> to our Support team (refer here for the <a target="_blank" href="${userGuide}">user guide</a>).`
    }
];

export const JwtTokenExpiredCode = '1001';
export const ConnectVPNCode = '1002';
export const SystemErrorCode = '1004';
export const FileSizeLlimitationCode = '2001';
export const FileTypeError = '2002';
