<header>
  <!-- <a class="navbar-brand text-white font-weight-bold pl-3">
    CIO Applied Intelligence Studio - Pricing Optimization
  </a> -->
  <div class="ie-alert" [hidden]="closeFlag">
    To take advantage of all the features of Accenture Translate, please install Google Chrome or open Accenture Translate using Chrome.
    <button type="button" class="close-alert" (click)="closeAlert()"><span>×</span></button>
  </div>
  <div class="translate-header">
    <img class="translate-logo" src="./assets/images/translate-logo.png" alt="" height="58px" width="427px">
  </div>
  <!-- <img src="./assets/images/Header.png" alt=""> -->
  <!-- <a style="color:#3f4851" [routerLink]="['/store']">|</a> -->
  <div class="logout" (click)="logout()">
    <img src="./assets/images/logout.png" width="30px" height="26px" alt=""> Logout
  </div>
</header>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <button type="button" ngbAutofocus class="modal-title" (click)="c('Save click')">
      <svg id="i-arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24" height="24" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
          <path d="M10 6 L2 16 10 26 M2 16 L30 16" />
      </svg>
      Back to translate
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-custome-content">
      <h1 class="legal-title">
        Privacy Statement
      </h1>
      <p>
        Please be aware that any personal data that you and others provide through
        this tool may be processed by Accenture.
      </p>
      <p>
        The protection of your personal data is very important to Accenture.
        Accenture is committed to keeping your personal data secure, and
        processing it in accordance with, applicable data protection laws
        and our internal policies, including
        <a
          [href]="countryUrl"
          target="_blank"
          rel="noopener noreferrer"
          >Accenture’s Global Data Privacy Policy 0090.</a
        >
      </p>
      <p>
        Your decision to provide your personal data to Accenture is
        voluntary. However, given that this tool requires personal data to
        function, you will not be able to use this tool if you do not
        provide your personal data.
      </p>
      <p>
        Before providing any personal data through this tool, Accenture
        invites you to carefully read its
        <a
          [href]="dataUrl"
          target="_blank"
          rel="noopener noreferrer"
          >privacy statement</a
        >, which includes important information on why and how Accenture is
        processing your personal data.
      </p>
      <p class="studio-logo">
      </p>
    </div>
  </div>
</ng-template>
