<a
  href="javascript: void(0)"
  (click)="open(privacyStatement)"
  class="tra-footer-terms-btn px-2"
  >Privacy Statement
  <span class="sr-only">A modal window will be open</span>
</a>
<ng-template #privacyStatement let-modal>
  <div class="modal-header">
    <h1 class="modal-title" id="modal-basic-title">Privacy Statement</h1>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body py-4">
    <p>
      Please be aware that any personal data that you and others provide through
      this tool may be processed by Accenture.
    </p>
    <p>
      The protection of your personal data is very important to Accenture.
      Accenture is committed to keeping your personal data secure, and
      processing it in accordance with, applicable data protection laws
      and our internal policies, including
      <a
        [href]="countryUrl"
        target="_blank"
        rel="noopener noreferrer"
        >Accenture’s Global Data Privacy Policy 0090.</a
      >
    </p>
    <p>
      Your decision to provide your personal data to Accenture is
      voluntary. However, given that this tool requires personal data to
      function, you will not be able to use this tool if you do not
      provide your personal data.
    </p>
    <p>
      Before providing any personal data through this tool, Accenture
      invites you to carefully read its
      <a
        [href]="dataUrl"
        target="_blank"
        rel="noopener noreferrer"
        >privacy statement</a
      >, which includes important information on why and how Accenture is
      processing your personal data.
    </p>
  </div>
  <div class="modal-footer pt-0">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>
