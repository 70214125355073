import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../core/services/CommonService';
import { datadogRum } from '@datadog/browser-rum';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'rebar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  countryUrl =
    'https://policies.accenture.com/Pages/0001-0100/0090.aspx';
  dataUrl =
    'https://in.accenture.com/protectingaccenture/data-security/5422-2/';

  closeFlag = false;

  @ViewChild('content', {static: true}) content:any;

  constructor(config: NgbModalConfig,
              private commonService: CommonService,
              private modalService: NgbModal,
              private auth: MsalService) {
    config.backdrop = 'static';
    config.keyboard = true;
  }

  ngOnInit() {
    this.closeFlag = !this.commonService.isIE() && !this.commonService.isFireFox();
  }
  
  logout() {
    this.auth.logout();
  }

  closeAlert() {
    this.closeFlag = true;
  }

  open(content: any) {
    datadogRum.addAction('trs_AppTerms',{
      language: {
        language: `trs_ClickLegalSpecifications`,
      }
    })
    this.modalService.open(content, { windowClass: 'modal-custome'});
  }
}
