import { Injectable } from '@angular/core';

@Injectable()
export class RecordService {
    context: any;
    config = {
        channelCount: 1,
        numberOfInputChannels: 1,
        numberOfOutputChannels: 1,
        sampleBits: 16,
        sampleRate: 8000,
        bufferSize: 4096,
    };
    audioInput: any;
    recorder: any;
    audioData: any;

    constructor() {
    }

    start() {
        (navigator as any).getUserMedia({ audio: true }
            , (stream : any) => {
                this.context = new AudioContext();
                this.audioInput = this.context.createMediaStreamSource(stream);
                const volume = this.context.createGain();
                this.audioInput.connect(volume);
                this.recorder = this.context.createScriptProcessor(
                    this.config.bufferSize,
                    this.config.channelCount,
                    this.config.channelCount);

                // tslint:disable-next-line: variable-name
                const _this = this;
                this.audioData = {
                    size: 0
                    , buffer: []
                    , inputSampleRate: this.context.sampleRate
                    , inputSampleBits: 16
                    , outputSampleRate: this.config.sampleRate
                    , oututSampleBits: this.config.sampleBits
                    , input: function call(data: any) {
                        this.buffer.push(new Float32Array(data));  // Float32Ar
                        this.size += data.length;
                    }
                    , getRawData: function call() {
                        const data = new Float32Array(this.size);
                        let offset = 0;

                        // tslint:disable-next-line: prefer-for-of
                        for (let i = 0; i < this.buffer.length; i++) {
                            data.set(this.buffer[i], offset);
                            offset += this.buffer[i].length;
                        }
                        const getRawDataion = Math.floor(this.inputSampleRate / this.outputSampleRate);
                        const length = data.length / getRawDataion;
                        const result = new Float32Array(length);
                        let index = 0;
                        let j = 0;
                        while (index < length) {
                            result[index] = data[j];
                            j += getRawDataion;
                            index++;
                        }
                        return result;
                    }
                    , getFullWavData: function call() {
                        const sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
                        const sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
                        const bytes = this.getRawData();
                        const dataLength = bytes.length * (sampleBits / 8);
                        const buffer = new ArrayBuffer(44 + dataLength);
                        let data = new DataView(buffer);
                        let offset = 0;
                        const writeString = function call1(str: any) {
                            for (let i = 0; i < str.length; i++) {
                                data.setUint8(offset + i, str.charCodeAt(i));
                            }
                        };
                        writeString('RIFF'); offset += 4;
                        data.setUint32(offset, 36 + dataLength, true); offset += 4;
                        writeString('WAVE'); offset += 4;
                        writeString('fmt '); offset += 4;
                        data.setUint32(offset, 16, true); offset += 4;
                        data.setUint16(offset, 1, true); offset += 2;
                        data.setUint16(offset, _this.config.channelCount, true); offset += 2;
                        data.setUint32(offset, sampleRate, true); offset += 4;
                        data.setUint32(offset, _this.config.channelCount * sampleRate * (sampleBits / 8), true); offset += 4;
                        data.setUint16(offset, _this.config.channelCount * (sampleBits / 8), true); offset += 2;
                        data.setUint16(offset, sampleBits, true); offset += 2;
                        writeString('data'); offset += 4;
                        data.setUint32(offset, dataLength, true); offset += 4;
                        data = this.reshapeWavData(sampleBits, offset, bytes, data);
                        return new Blob([data], { type: 'audio/wav' });
                    }
                    , closeContext: function call() {
                        _this.context.close();
                    }
                    , getPureWavData: function call(offset:any) {
                        const sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
                        const bytes = this.getRawData();
                        const dataLength = bytes.length * (sampleBits / 8);
                        const buffer = new ArrayBuffer(dataLength);
                        let data = new DataView(buffer);
                        data = this.reshapeWavData(sampleBits, offset, bytes, data);
                        return new Blob([data], { type: 'audio/wav' });


                    }
                    , reshapeWavData: function call(sampleBits:any, offset:any, iBytes:any, oData:any) {
                        if (sampleBits === 8) {
                            for (let i = 0; i < iBytes.length; i++, offset++) {
                                const s = Math.max(-1, Math.min(1, iBytes[i]));
                                let val = s < 0 ? s * 0x8000 : s * 0x7FFF;
                                val = Math.floor(255 / (65535 / (val + 32768)));
                                oData.setInt8(offset, val, true);
                            }
                        } else {
                            for (let i = 0; i < iBytes.length; i++, offset += 2) {
                                const s = Math.max(-1, Math.min(1, iBytes[i]));
                                oData.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
                            }
                        }
                        return oData;
                    }
                };

                this.recorder.onaudioprocess = (e:any) => {
                    this.audioData.input(e.inputBuffer.getChannelData(0));
                    if (e.playbackTime >= 60) {
                        this.recorder.disconnect();
                    }
                };
                this._start();
            }
            , function call(error: any) {
                switch (error.code || error.name) {
                    case 'PERMISSION_DENIED':
                    case 'PermissionDeniedError':
                        alert('Permission Denied Error');
                        break;
                    case 'NOT_SUPPORTED_ERROR':
                    case 'NotSupportedError':
                        alert('Not Supported Error');
                        break;
                    case 'MANDATORY_UNSATISFIED_ERROR':
                    case 'MandatoryUnsatisfiedError':
                        alert('Mandatory Unsatisfied Error');
                        break;
                    default:
                        alert(`Can not open Microphone. Error message: ${error.code || error.name}`);
                        break;
                }
            });
    }

    stop() {
        this.recorder.disconnect();
        this.audioInput.mediaStream.getTracks()[0].stop();
    }

    getBlob() {
        this.stop();
        return this.audioData.getFullWavData();
    }

    private _start() {
        this.audioInput.connect(this.recorder);
        this.recorder.connect(this.context.destination);
    }

}
