import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { REBAR_AUTH_GUARD } from 'src/app/core/rebarauth/rebar.auth.module';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { TranslateComponent } from './feature/translate/translate.component';
import { TranslateFComponent } from './feature/translate_f/translate_f.component';
import { AppComponent } from './root/app.component';
import { AuthorizationGuard } from './core/services/authorization.guard';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';


const appRoutes: Routes = [
  

      {
        path: '',
        component: TranslateComponent,
        canActivate: [REBAR_AUTH_GUARD],
      },
      {
        path: 'translate_f',
        component: TranslateFComponent,
        canActivate: [REBAR_AUTH_GUARD],
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent
      },
      {
        path: 'not-authorized',
        component: NotAuthorizedComponent
      }
    

];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
