import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService } from '../../core/services/DataService';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Document, Paragraph, Packer, TextRun, HeadingLevel, LevelFormat, AlignmentType, convertInchesToTwip } from 'docx';
import { saveAs } from "file-saver";
import { compile, convert } from 'html-to-text';

import * as pdfMake from "pdfmake/build/pdfMake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");


@Component({
  selector: 'rebar-mmp',
  templateUrl: './translate_f.component.html',
  styleUrls: ['./translate_f.component.scss']
})
export class TranslateFComponent implements OnInit {

  // tslint:disable-next-line: variable-name
  translate_f: SafeHtml = '';

  closeResult = '';
  filetype="PDF";
  target:string="";

  constructor(private domSanitizer: DomSanitizer, private router: Router, private modalService: NgbModal, private service: DataService,) {
  }

  ngOnInit() {

    //localStorage.setItem("target", "my"); //for debugging languages
    this.target = localStorage.getItem("target")+"";
    console.log(this.target);

    if(this.service.isUserAuthorized()==false){
      this.router.navigate(['not-authorized']);
    }
    // tslint:disable-next-line: variable-name
    const translate_f = localStorage['translate_f'] || '';
     this.translate_f = translate_f;
     localStorage.removeItem('translate_f');
  }


 setFileType(selected:string){
  this.filetype = selected;
 }

download(){
  if(this.filetype == 'PDF'){
    this.makePDF();
  }
  else if(this.filetype == 'Docx'){
    this.makeDocx();
  }
  else if(this.filetype == 'Txt'){
    this.makeTxt();
  }
}

 
 open(content:any) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}


//html to docx parser code 
bullet_points_config = {
  reference: "my-bullet-points",
  levels: [
      {
          level: 0,
          format: LevelFormat.BULLET,
          text: "\u2022",
          alignment: AlignmentType.LEFT,
          style: {
              paragraph: {
                  indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) },
              },
          },
      },
  ],
}


parseNode = (root: Element, parents_array: any[], children_paras: Paragraph[]): void => {
  if (root.childNodes.length === 0) {
      if (root.tagName == "BR") {
          children_paras.push(new Paragraph({
              children: [
                  new TextRun({
                      text: "",
                      break: 1,
                  })
              ]
          }))
      }
      else {
          children_paras.push(new Paragraph({
            style:"Normal",
              text: root.textContent?.trim() || "",
              heading: parents_array.at(-1)?.tagName == "H1" ?
                  HeadingLevel.HEADING_1 :
                  parents_array.at(-1)?.tagName == "H2" ?
                      HeadingLevel.HEADING_2 :
                      parents_array.at(-1)?.tagName == "H3" ?
                          HeadingLevel.HEADING_3 :
                          parents_array.at(-1)?.tagName == "H4" ?
                              HeadingLevel.HEADING_4 :
                              parents_array.at(-1)?.tagName == "H5" ?
                                  HeadingLevel.HEADING_5 :
                                  parents_array.at(-1)?.tagName == "H6" ?
                                      HeadingLevel.HEADING_6 :
                                      undefined,
              numbering: parents_array.at(-1)?.tagName == "LI" ? {
                  reference: "my-bullet-points",
                  level: 0,
              } : undefined,
          }))
      }
  } else {
      for (let i = 0; i < root.childNodes.length; i++) {
          const element = root.childNodes[i];
          this.parseNode(element as Element, [...parents_array, root], children_paras)
      }
  }
}

 htmlToDocsParser = (html: string): Document => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  let parents_array: any[] = [];
  let child_array: Paragraph[] = [];
  this.parseNode(doc.body, parents_array, child_array)
  if(this.target =="ka"){
    const out = new Document({
      styles:{
        paragraphStyles: [
          {
            id: "Normal",
            name: 'Normal',
            run: {
              font: "Calibri",
              size: 22
            }
          }
        ]
      },
        numbering: {
            config: [this.bullet_points_config]
        },
        sections: [
            {
                children: child_array
            }
        ]
    })
    return out;
  }else{
    const out = new Document({
      styles:{
        paragraphStyles: [
          {
            id: "Normal",
            name: 'Normal',
            run: {
              font: "Consolas",
              size: 22
            }
          }
        ]
      },
        numbering: {
            config: [this.bullet_points_config]
        },
        sections: [
            {
                children: child_array
            }
        ]
    })
    return out;
  }

}



public makePDF() {

  (pdfMake as any).fonts = { 
    ArialUnicode: { //default font
      normal: `${window.location.origin}/assets/fonts/Arial-Unicode-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/Arial-Unicode-Regular.ttf`,
      italics: `${window.location.origin}/assets/fonts/Arial-Unicode-Regular.ttf`
    },
    NotoSerifEthiopic:{ //Amharic font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Ethiopic/NotoSerifEthiopic-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Ethiopic/NotoSerifEthiopic-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Ethiopic/NotoSerifEthiopic-Regular.ttf`
    },
    NotoSerifKhmer:{ //Khmer font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Khmer/NotoSansKhmer-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Khmer/NotoSansKhmer-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Khmer/NotoSansKhmer-Regular.ttf`
    },
    NotoSerifLao:{ //Lao font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Lao/NotoSerifLao-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Lao/NotoSerifLao-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Lao/NotoSerifLao-Regular.ttf`
    },

    AnekMalayalam:{ //Malayalam font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Malayalam/AnekMalayalam-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Malayalam/AnekMalayalam-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Malayalam/AnekMalayalam-Regular.ttf`
    },
    Padauk:{ //Myanmar font
      normal: `${window.location.origin}/assets/fonts/Padauk/MyanmarSagar.ttf`,
      bold: `${window.location.origin}/assets/fonts/Padauk/MyanmarSagar.ttf`,
      italics: `${window.location.origin}/assets/fonts/Padauk/MyanmarSagar.ttf`,
    },
    NotoSerifSinhala:{ //Sinhala font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Sinhala/NotoSerifSinhala-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Sinhala/NotoSerifSinhala-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Sinhala/NotoSerifSinhala-Regular.ttf`,
    },
    NotoSerifTelugu:{ //Telugu font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Telugu/NotoSerifTelugu-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Telugu/NotoSerifTelugu-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Telugu/NotoSerifTelugu-Regular.ttf`,
    },
    NotoSerifGeorgian:{ //Georgian font
      normal: `${window.location.origin}/assets/fonts/NotoSerif/Georgian/NotoSerifGeorgian-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/NotoSerif/Georgian/NotoSerifGeorgian-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/NotoSerif/Georgian/NotoSerifGeorgian-Regular.ttf`,
    },
    
 }

  var htmlString:string = document.getElementById('htmlData')?.outerHTML as string;
  var html = htmlToPdfmake(htmlString);

  switch(this.target){
    case "ar":{ //Arabic 
      this.getRTSPDF(); 
      break;
    }
    case "am":{ ////Amharic font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifEthiopic'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }

    case "km":{ //Khmer font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifKhmer'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    case "lo":{ //Lao font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifLao'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    case "he":{ //Hebrew 
      this.getRTSPDF(); 
      break;
    }
    case "sd":{ //Sindi 
      this.getRTSPDF(); 
      break;
    }
    case "ur":{ //Urdu
      this.getRTSPDF(); 
      break;
    }
    case "ug":{ //Uyghur 
      this.getRTSPDF(); 
      break;
    }
    case "yi":{ //Yiddish 
      this.getRTSPDF(); 
      break;
    }
    case "ps":{ //Pashto 
      this.getRTSPDF(); 
      break;
    }
    case "fa":{ //Persian
      this.getRTSPDF(); 
      break;
    }
    case "ml":{ //Malayalam font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'AnekMalayalam'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    case "my":{ //Myanmar font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'Padauk'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    case "si":{ //Sinhala font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifSinhala'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    case "te":{ //Sinhala font
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifTelugu'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    
    case "ka":{ //Georgianfont
      let documentDefinition = { content: html, defaultStyle: {
        font: 'NotoSerifGeorgian'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
    
    default:{
      let documentDefinition = { content: html, defaultStyle: {
        font: 'ArialUnicode'
      }};
      pdfMake.createPdf(documentDefinition).download("translated.pdf");   
      break;
    }
  }
}


async makeDocx(){

 var htmlString:string = document.getElementById('htmlData')?.outerHTML as string;
  const final_doc = this.htmlToDocsParser(htmlString);
  
await Packer.toBlob(final_doc).then((blob) => {
                saveAs(
                  blob,
                  `translated.docx`
                );
                console.log("Document created successfully");
              });
}


async makeTxt(){
  const options = {

  };
const compiledConvert = compile(options); // options passed here
const htmls = document.getElementById('htmlData')?.outerHTML as string;
const texts = compiledConvert(htmls);
var translated: string = texts;
var file = new File([translated], "translated.txt");
saveAs(file);

 }

 getRTSPDF(){
  let quotes: any = document.getElementById('htmlData')
  
  var HTML_Width = quotes.clientWidth;
  var HTML_Height = quotes.clientHeight;
  var top_left_margin = 15;
  var PDF_Width = HTML_Width+(top_left_margin*2);
  var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
  var canvas_image_width = HTML_Width;
  var canvas_image_height = HTML_Height;
  
  var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
  

  html2canvas(quotes).then((canvas) => {
    canvas.getContext('2d');
    
    console.log(canvas.height+"  "+canvas.width);
    
    
    var imgData = canvas.toDataURL("image/jpeg", 1.0);
    var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
    
    
    for (var i = 1; i <= totalPDFPages; i++) { 
      pdf.addPage([PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
    }
    
      pdf.save("translated.pdf");
      });
};
 




}

