import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rebar-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.css']
})
export class NotAuthorizedComponent implements OnInit, AfterViewInit {

  constructor(private rebarAuthService: RebarAuthService, private router: Router) {
  }

  ngOnInit() {
  }
  

  ngAfterViewInit(){
    setTimeout(() => {
      if (this.rebarAuthService.checkRole('authenticated')==true){
        this.router.navigate(['']);
        console.log("user authenticated in NA Page");
      } 
    }, 1000);



  }
}
