import { Component, OnInit, ViewChild, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';
import { DataService } from '../../core/services/DataService';
import { RecordService } from '../../core/services/RecordService';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Languages from '../../../data/language.json';
//import { DatadogSessionService } from 'src/app/core/services/datadog-session.service';
import { CommonService } from '../../core/services/CommonService';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';

import {
  ErrorMessage,
  JwtTokenExpiredCode,
  ConnectVPNCode,
  SystemErrorCode,
  FileSizeLlimitationCode,
  FileTypeError
} from '../../../data/errorMessage';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment'


interface InputLanguage {
  key: string;
  value: string;
  speech?: boolean;
}

@Component({
  selector: 'rebar-mmp',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit, AfterViewInit {

  @ViewChild('audio', { static: true }) audio: any;
  @ViewChild('searchLanguage', { static: true }) searchLanguage: any;
  @ViewChild('content1', { static: true }) content: any;
  @ViewChild('warning', { static: true }) warning: any;
  @ViewChild('lenggwa', { static: true }) lenggwa: any;

  showSearch = false;
  countryUrl =
    'https://policies.accenture.com/Pages/0001-0100/0090.aspx';
  dataUrl =
    'https://in.accenture.com/protectingaccenture/data-security/5422-2/';
  sourceLC = 'detect';
  aidUrl = "https://kxdocuments.accenture.com/Contribution/18973c2f-da71-453d-af90-71f192299b7c";
  targetLC = 'en';
  files: File[] = [];
  // tslint:disable-next-line: variable-name
  is_html = false;
  isPlay = false;
  // isRequest = false;
  loading = false;
  languages: InputLanguage[] = [];
  languagesClone: InputLanguage[] = [];
  inputLanguageGroun: InputLanguage[];
  outputLanguageGroup: InputLanguage[];
  searchFlag = 0;
  searchLanguageText = '';
  warningMessage = '';
  warningType = '';
  warningTitle = 'Accenture Translate Warning';
  textInput = '';
  textOutput = [];
  tempLang = '';


  constructor(private modalService: NgbModal,
    private service: DataService,
    private recordService: RecordService,
    private commonService: CommonService,
    //private DatadogSessionService: DatadogSessionService,
    private domSanitizer: DomSanitizer,
    private rebarAuthService: RebarAuthService,
    private router: Router,
    // tslint:disable-next-line: variable-name
    private _clipboardService: ClipboardService,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = true;

    this.inputLanguageGroun = [{
      key: 'DETECT LANGUAGE', value: 'detect'
    }, {
      key: 'ENGLISH', value: 'en', speech: true
    }, {
      key: 'CHINESE', value: 'zh-CN', speech: true
    }, {
      key: 'SPANISH', value: 'es', speech: true
    }, {
      key: 'JAPANESE', value: 'ja', speech: true
    }];

    this.outputLanguageGroup = [{
      key: 'ENGLISH', value: 'en', speech: true
    }, {
      key: 'CHINESE', value: 'zh-CN', speech: true
    }, {
      key: 'SPANISH', value: 'es', speech: true
    }, {
      key: 'JAPANESE', value: 'ja', speech: true
    }];

    const allLanguages = (Languages as any).default;
    allLanguages.forEach((language: any) => {
      this.languages.push(language);
    });

    this.languagesClone = [...this.languages];
  }
  ngAfterViewInit() {

      if(this.service.isUserAuthorized()==true){
        setTimeout(() => {
          this.open(this.content);
        }, 1);
      }
      else {
            console.log("unauthorized on translate page")
            this.router.navigate(['not-authorized']);
        }
    
  }

  ngOnInit() {



  }

  enableSpeechToText(): boolean {
    const selected = this.languagesClone.filter(x => x.value === this.sourceLC)[0];
    return (!this.commonService.isFireFox() && !this.commonService.isIE() && this.sourceLC !== 'detect' && selected.speech) as boolean;
  }

  // download_file(dataUri) {
  //   if (navigator['msSaveBlob']) {
  //     const blob = this.dataURLToBlob(dataUri);
  //     navigator.msSaveBlob(blob, this.files[0].name);
  //   } else {
  //     const pom = document.createElement('a');
  //     pom.setAttribute('href', dataUri);
  //     pom.setAttribute('download', this.files[0].name);
  //     if (document.createEvent) {
  //         const event = document.createEvent('MouseEvents');
  //         event.initEvent('click', true, true);
  //         pom.dispatchEvent(event);
  //     } else {
  //         pom.click();
  //     }
  //   }
  // }

  copy() {
    this._clipboardService.copyFromContent(this.textOutput.join('\n'));
  }

  speechToTextTooltip(): any {
    if (this.commonService.isFireFox() || this.commonService.isIE()) {
      return 'The functionality of speech to text is only working in Chrome browser now';
    }

    if (this.sourceLC === 'detect') {
      return 'Select a specific language to enable voice input';
    }
    const selected = this.languagesClone.filter(x => x.value === this.sourceLC)[0];
    if (!selected.speech) {
      return `Voice input isn’t available for ${selected.key}`;
    }
  }

  chooseLanguage(language: any) {
    if (this.searchFlag === 0) {
      if (!this.inputLanguageGroun.some(x => x.value === language.value)) {
        this.inputLanguageGroun.pop();
        this.inputLanguageGroun.splice(1, 0, language);
      }
      this.sourceLC = language.value;
    } else {
      if (!this.outputLanguageGroup.some(x => x.value === language.value)) {
        this.outputLanguageGroup.pop();
        this.outputLanguageGroup.unshift(language);
      }
      this.targetLC = language.value;
    }

    this.showSearch = false;
  }

  searchLanguageInput(languageText: any) {
    const text = languageText.target.value.trim().toLowerCase();
    if (text === '') {
      this.languages = this.languagesClone;
    } else {
      this.languages = this.languagesClone.filter(x => x.key.toLowerCase().indexOf(text) >= 0);
    }
  }

  wrapHtml(content: string) {
    if (!content) {
      return '';
    }
    return content;
  }

  showSearchLanguages(flag: any) {
    this.searchFlag = flag;
    this.showSearch = !this.showSearch;
    this.searchLanguageText = '';
    this.languages = this.languagesClone;
    setTimeout(() => {
      this.searchLanguage.nativeElement.focus();
    }, 1);
  }

  play() {
    if (!this.enableSpeechToText()) {
      datadogRum.addAction('trs_GetContent', {
        language: {
          language: `trs_ClickMic`,
        }
      })
      return;
    }
    datadogRum.addAction('trs_GetContent', {
      language: {
        language: `trs_StartRecording`,
      }
    })
    this.recordService.start();
    this.isPlay = true;
  }

  stop() {
    datadogRum.addAction('trs_GetContent', {
      language: {
        language: `trs_ClickMic`
      }
    })

    const url = this.recordService.getBlob();
    // this.audio.nativeElement.src = window.URL.createObjectURL(url);
    this.isPlay = false;
    this.audio.nativeElement.onended = function call() { };

    const fileReader: FileReader = new FileReader();
    fileReader.onload = () => {
      const dataString = (fileReader as any).result.toString();
      const audioUri = dataString.substr(dataString.indexOf('base64,') + 7);
      this.loading = true;
      this.service.speechToText(audioUri, this.sourceLC).subscribe((result: any) => {
        // this.isRequest = false;
        this.loading = false;
        if (result.status === 200) {
          this.textInput = result.message[0];
          this.files = [];
        } else {
          this.showError(result);
        }
      }, (error) => this.handleError(error));
    };
    fileReader.readAsDataURL(url);
  }

  download() {
    let content = this.textOutput.join('\n');
    const disclaimer ="*This translation is intended for internal use only and is not approved to be shared externally. If you need to share this translation externally, you need to certify it first with an official translation service.*" + '\n'+'\n'+'\n'+'\n';
    content = disclaimer + content;
    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveBlob(new Blob([content]), 'translate.txt');
    } else {
      const pom = document.createElement('a');
      pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
      pom.setAttribute('download', 'translate.txt');
      if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
      } else {
        pom.click();
      }
    }
  }

  translate(event: any) {
    const lng = event.currentTarget.value === '' || event.currentTarget.value == undefined ? 'en' : event.currentTarget.value;
    datadogRum.addAction('trs_Translate', {
      language: {
        language: `trs_ClickTranslate`,
      }
    })
    if (this.tempLang === '') {
      datadogRum.addAction('trs_LanguageSelection', {
        language: {
          language: `trs_ClickFrom_${lng}`,
        }
      });
      this.tempLang = lng;
    }
    this.loading = true;
    if (this.files.length === 0) {
      this.service.translate(this.textInput, this.sourceLC, this.targetLC).subscribe((result: any) => {
        this.loading = false;
        if (result.status === 200) {
          // this.textOutput = result.message;
          this.is_html = false;
          this.textOutput = result.message;
          localStorage.setItem("target", this.targetLC);
        } else {
          this.showError(result);
        }
      }, (error) => this.handleError(error));
    } else {
      const fileReader: FileReader = new FileReader();
      fileReader.onload = () => {
        const dataString = (fileReader as any).result.toString();
        const uri = dataString.substr(dataString.indexOf('base64,') + 7);
        this.service.translateDocument(uri, this.files[0].type, this.sourceLC, this.targetLC).subscribe((result: any) => {
          this.loading = false;
          if (result.status === 200) {
            this.is_html = result.is_html;
            localStorage.setItem("target", this.targetLC);
            if (!result.is_html) {
              this.openTranslatePage(result.message.map((msg: any) => `<p>${msg}</p>`).join(''));
            } else {
              this.openTranslatePage(result.message.join(''));
            }
          } else {
            this.showError(result);
          }
        }, (error) => this.handleError(error));
      };
      fileReader.readAsDataURL(this.files[0]);
    }
  }

  openTranslatePage(content: any) {
    localStorage['translate_f'] = content;
    const pom = document.createElement('a');
    pom.setAttribute('href', `/translate_f`);
    pom.setAttribute('target', `_blank`);
    pom.setAttribute('rel', `noopener noreferrer`);
    if (document.createEvent) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
    setTimeout(() => {
      //localStorage.removeItem('translate_f');
    }, 1);
  }

  onFilesAdded(event: any) {
    datadogRum.addAction('trs_GetContent', {
      language: {
        language: `trs_ClickUploadFile`,
      }
    })
    if (event.addedFiles.length > 0) {
      this.files[0] = event.addedFiles[0];
      this.textInput = '';
      this.textOutput = [];
    }

    if (event.rejectedFiles.length > 0) {
      if (event.rejectedFiles[0].reason === 'type') {
        this.showError(FileTypeError);
      } else {
        this.showError(FileSizeLlimitationCode);
      }
    }
  }

  remove() {
    this.files = [];
    this.textOutput = [];
  }




  OnInputLanguageSelect(event: any) {
    this.tempLang = event.currentTarget.value;
    datadogRum.addAction('trs_LanguageSelection', {
      language: {
        language: `trs_ClickFrom_${event.currentTarget.value}`,
      }
    })
  }

  OnOutputLanguageSelect(event: any) {
    datadogRum.addAction('trs_LanguageSelection', {
      language: {
        language: `trs_ClickTo_${event.currentTarget.value}`,
      }
    })
  }

  isIEorFireFox() {
    return this.commonService.isFireFox() || this.commonService.isIE();
  }


  private open(content: any) {
    this.modalService.open(content, { windowClass: 'modal-custome' });
  }

  private handleError(error: any) {
    this.loading = false;
    if (error.error && error.error.message) {
      if (error.error.message === 'jwt expired') {
        this.showError(JwtTokenExpiredCode);
        // Delete the eso token from the session storage:
        sessionStorage.removeItem('msal.idtoken');
      } else {
        this.showErrorMessage(error.error.message);
      }
    } else {
      if (error.status === 0) {
        this.showError(ConnectVPNCode);
      } else {
        this.showErrorMessage(error.message);
      }
    }
  }

  private showError(err: any | string) {
    const code = err.error_code || err;
    let error: any = this.getErrorMessageByCode(code);
    if (!error) {
      error = this.getErrorMessageByCode(SystemErrorCode);
    }
    let errorMessage = error.error_message;
    const errorTitle = error.error_title;
    const type = error.error_type;
    if (error.include_message) {
      errorMessage = `${err.error_message} ${errorMessage}`;
    }
    this.showErrorMessage(errorMessage, type, errorTitle);
  }

  private getErrorMessageByCode(code: any) {
    const message = ErrorMessage.find(error => {
      return error.error_code === code;
    });
    return message;
  }


  private showErrorMessage(message: any, type = 'error', title?: string) {
    this.warningMessage = message;
    this.warningType = type;
    this.warningTitle = title || 'Accenture Translate Warning';
    if (!this.warningMessage) {
      return;
    }
    this.modalService.open(this.warning, {
      centered: true,
      windowClass: 'warning-modal-translate static-modal'
    });
  }
}

