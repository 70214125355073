<!-- FOOTER -->
<footer class="d-flex align-items-center">
  <div class="container-fluid text-center">
    <p class="m-0">
      Copyright {{currentYear}} Accenture. All rights reserved. Accenture Restricted. For internal use only.
      <rebar-terms-of-use></rebar-terms-of-use> |
      <rebar-privacy-statement></rebar-privacy-statement> |  
      <a target="_blank" rel="noopener noreferrer" [href]="supportUrl">Get Support</a>  |  
      <a target="_blank" rel="noopener noreferrer" [href]="contactUs">Contact Us</a>
    </p>
  </div>
</footer>
<!-- END FOOTER -->
