<div class="not-authorized-container container">
    <div class="row">
        <h1>Not Authorized</h1>
        <p>This tool is currently restricted to Legal users only.
            If you are part of Legal Organization, check that your Legal Onboarding process is completed.
            You can follow up with your Manager on your Legal onboarding status.<br>
            If you are not part of Legal Organization and you have a need for translation capability, check
            different alternatives below:</p>
        <ul>
            <li>
                <a
                    href="https://support.microsoft.com/en-us/office/translate-a-message-in-teams-d8926ce9-d6a6-47df-a416-f1adb62d3194">MS
                    Teams message translations</a>
            </li>
            <li>
                <a
                    href="https://support.microsoft.com/en-us/office/translate-text-into-a-different-language-287380e4-a56c-48a1-9977-f2dca89ce93f">Microsoft
                    Office translations</a>
            </li>
            <li>
                <a
                    href="https://support.microsoft.com/en-us/topic/use-microsoft-translator-in-microsoft-edge-browser-4ad1c6cb-01a4-4227-be9d-a81e127fcb0b">Microsoft
                    Edge translations</a>
            </li>
        </ul>
    </div>
</div>
