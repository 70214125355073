import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Containers
import { TranslateComponent } from './translate.component';
import { NgbModule, NgbTypeaheadModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClipboardModule } from 'ngx-clipboard';
import { HeaderComponent } from '../../shared/header/header.component';

import { FooterComponent } from '../../shared/footer/footer.component';
import { TermsOfUseComponent } from '../../shared/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from '../../shared/privacy-statement/privacy-statement.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// Routes
const ROUTES: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    NgbTypeaheadModule,
    FormsModule,
    DndModule,
    NgbModule,
    NgxDropzoneModule,
    NgbAlertModule,
    NgSelectModule,
    ClipboardModule,
    NgbModalModule
  ],
  declarations: [
    TranslateComponent,
    HeaderComponent,
    FooterComponent,
    TermsOfUseComponent,
    PrivacyStatementComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: []
})
export class TranslateModule { }
