
<ng-container *ngIf="show">

<router-outlet id="content-wrap"></router-outlet>
<notificationframework-alert></notificationframework-alert>
<notificationframework-toast></notificationframework-toast>
<notificationframework-modal></notificationframework-modal>

</ng-container>

<ng-template #dialogcontent>
    <div class="modal-body">
      <div class="modal-custome-content">
        <br><br>
        <h4 class="dialog-content">
            Warning! Your login session has expired. Please click the button to refresh the page.
        </h4>
        <br><br>
        <div class="studio-logo">
          <button type="button" ngbAutofocus class="btn btn-primary" (click)="refresh()">Refresh</button>
          <div class="space"></div>
        </div>
        <br><br>
      </div>
    </div>
  </ng-template>