import { Component, OnInit } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rebar-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  closeResult: any;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}
  // Modal
  open(content: any) {
    datadogRum.addAction('trs_AppTerms',{
      language: {
        language: `trs_ClickTermsConditions`,
      }
    })
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'studio-modal-translate static-modal'
    });
  }
  // End Modal
}
