import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Containers
import { TranslateFComponent } from './translate_f.component';
import { NgbModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';

// Routes
const ROUTES: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    FormsModule,
    DndModule,
    NgbModule,
    NgbAlertModule,
  ],
  declarations: [TranslateFComponent],
  schemas: [NO_ERRORS_SCHEMA],
  providers: []
})
export class TranslateFModule { }
