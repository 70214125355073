import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rebar-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactUs = "https://kxdocuments.accenture.com/contribution/3ef4d63e-9144-42cf-84f4-f21204d2accc";
  //supportUrl = "https://in.accenture.com/legalintellectcontractexploration/accenture-translate/";
  supportUrl = "https://in.accenture.com/accenturetranslate/"; //new support site
  currentYear = new Date().getFullYear();

  constructor() {}


  ngOnInit() {}
}
