import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';

@Injectable()
export class DataService {
    private role = 'authenticated';

    private url = environment.proxyUrl;
    constructor(
        private http: HttpClient,
        private router: Router,
        private authservice: RebarAuthService) { }

    public isUserAuthorized() {
        if (this.authservice.checkRole(this.role)) 
          return true;
          else return false;
    }
    
    public speechToText(datauri: string, sourceLc: string ) {
        const uri = this.buildDataUri(datauri, 'audio/wav');
        const requestString = this.buildAudioRequestBody(uri, sourceLc);
        return this.http.post(this.url,
            JSON.stringify(requestString),
            { headers: this.setRequestHeader() });
    }

    public translate(message: string, sourceLc: string, targeLc: string) {
        const requestString = this.buildTextRequestBody(message, sourceLc, targeLc);
        return this.http.post(this. url,
            JSON.stringify(requestString),
            { headers: this.setRequestHeader() });
    }

    public translateDocument(datauri: string, type: string, sourceLc: string, targeLc: string) {
        const uri = this.buildDataUri(datauri, type);
        const requestString = this.buildDocumentRequestBody(uri, sourceLc, targeLc);
        return this.http.post(this.url,
            JSON.stringify(requestString),
            { headers: this.setRequestHeader() });
    }

    private buildDataUri(uri: string, type: string) {
        return `data:${type};charset=utf-8;base64,${uri}`;
    }

    private buildTextRequestBody(message: string, sourceLc: string, targeLc: string) {
        return {
            configurationid: environment.configurationid,
             resttype: 'POST',
             methodname: '',
             jsonpostdata:
             {
                message,
                source_lc: sourceLc,
                target_lc: targeLc
             }
          };
    }

    private buildDocumentRequestBody(uri: string, sourceLc: string, targeLc: string) {
        return {
            configurationid: environment.configurationid,
             resttype: 'POST',
             methodname: '',
             jsonpostdata:
             {
                uri,
                source_lc: sourceLc,
                target_lc: targeLc
             }
          };
    }

    private buildAudioRequestBody(uri: string, sourceLc: string) {
        return {
            configurationid: environment.configurationid,
             resttype: 'POST',
             methodname: '',
             jsonpostdata:
             {
                audio: uri,
                source_lc: sourceLc
             }
          };
    }

    private setRequestHeader(): HttpHeaders {
        const requestHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return requestHeader;
    }
}
