import { Component, OnInit, ViewChild } from '@angular/core';
import { RebarAuthService } from '../core/rebarauth/rebar.auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { datadogRum } from '@datadog/browser-rum';
import { AppConfigService } from '../core/services/app-config.service';
//import { DatadogSessionService } from '../core/services/datadog-session.service';
declare let IM_CLD_CentRum: any;
import {
  MsalBroadcastService, MsalService
} from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private storage: any;
  @ViewChild('dialogcontent', { static: true }) dialog: any;
  exp: string | number | NodeJS.Timeout | undefined;
  expTime: Subject<any> = new Subject();

  public show:boolean = false;
  private readonly destroying$ = new Subject<void>();
  constructor(private modalService: NgbModal,
    private rebarAuthService: RebarAuthService,
    private appConfigService: AppConfigService,
  //private datadogSessionService: DatadogSessionService,
    private msalBroadcastService: MsalBroadcastService,
    private auth: MsalService,
    private router: Router,
    private route:ActivatedRoute
  ) {
    this.setTimeout();
    this.expTime.subscribe(() => this.open(this.dialog));
  }
  setTimeout(){
    this.exp = setTimeout(() => this.expTime.next
    (undefined), 1000 * 60 * 60);
  }
  refresh(){
    this.auth.loginRedirect();
  }

  private open(dialog: any) {
    this.modalService.open(dialog, { windowClass: 'modal-custome' });
  }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe(event => {
          var route = (event as any).url
          if(route ==='/translate_f'){
            this.show = true;
          }else {
            localStorage.clear();
            if(!sessionStorage.getItem("userSession")){
              sessionStorage.setItem("userSession", "true");
              this.msalBroadcastService.inProgress$
              .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.destroying$)
              )
              .subscribe(() => {
                this.rebarAuthService.loginRedirect();
              })
            }
            setTimeout(() => {
             this.msalBroadcastService.inProgress$
             .pipe(
               filter((status: InteractionStatus) => status === InteractionStatus.None),
               takeUntil(this.destroying$))
             .subscribe(() => {
             this.show = true;
             })
            }, 500);
            setTimeout(() => {
              if(sessionStorage.getItem("userSession")){
                sessionStorage.removeItem("userSession");
              }
             }, 15000);



          }
      });

    this.rebarAuthService.authObserver$.subscribe((authStatus) => {
      if (authStatus) {
        console.log(`user is logged in ${authStatus}`);
        console.log(`Username ****** ${this.rebarAuthService.getUser()}`);  

      //Add these 2 lines of code for storing the User Token Claims in the session Storage
      const userTokenClaims = JSON.stringify(this.rebarAuthService.getUserClaims());
      const parsedClaims = JSON.parse(userTokenClaims);
      const payload = {
        businessOrg: parsedClaims.businessOrg,
        countryDescription: parsedClaims.countryDescription,
        countryCode:parsedClaims.countryCode,
        jobTitle:parsedClaims.jobTitle,
        jobFamily:parsedClaims.jobFamily,
        location: parsedClaims.location,
        locationCode: parsedClaims.locationCode,
        jobFamilyDescription: parsedClaims.jobFamilyDescription
      }
      //Set Item to Session Storage
      sessionStorage.setItem('ddrumsdk.tokenclaims', JSON.stringify(payload));
    
      }
    });
    // datadogRum.init({
    //   applicationId: (
    //     this.appConfigService.config['datadog'] as Record<string, string>
    //   )['appId'],
    //   clientToken: (
    //     this.appConfigService.config['datadog'] as Record<string, string>
    //   )['clientToken'],
    //   site: 'datadoghq.com',
    //   service: (
    //     this.appConfigService.config['datadog'] as Record<string, string>
    //   )['service'],
    //   env: (this.appConfigService.config['datadog'] as Record<string, string>)[
    //     'environment'
    //   ],
    //   sampleRate: 100,
    //   trackInteractions: true,
    //   useSecureSessionCookie: true,
    //   allowedTracingOrigins: [
    //     (this.appConfigService.config['datadog'] as Record<string, string>)[
    //       'allowedTracingOriginsDNS'
    //     ],
    //   ],
    // });

     //datadogRum.setUser({
       //id: this.datadogSessionService.setDataDogSessionId(),
     //});

      //Datadog Init
      IM_CLD_CentRum(this).log({
        applicationId: (this.appConfigService.config['datadog'] as Record<string, string>)['appId'],
        clientToken: (this.appConfigService.config['datadog'] as Record<string, string>)['clientToken'],
        service: (this.appConfigService.config['datadog'] as Record<string, string>)['service'],
        env: (this.appConfigService.config['datadog'] as Record<string, string>)['environment'],
        sampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: (this.appConfigService.config['datadog'] as Record<string, string>)['allowedTracingOriginsDNS'],
        useSecureSessionCookie: true,
      });

  }

  storeRoute() {
      const route = location.pathname + location.search;
      if (route !== undefined && route !== '') {
          const path = route.split('/')[1];
          if (path != null && path !== '') {
              this.storage.setItem('route', path);
          }
      }
  }
}
