export const environment = {
  production: false,
  providers: 'app',
  buildNumber: '',
  configuration: 'stage',
  proxyUrl: 'https://npd-127590-newgateway-7lyxa7w2.ue.gateway.dev/npd-127590-translate',
  configurationid: '2da32cfb-056c-4a88-b7da-8a9fdcd11aca',
  matomoInjectorUrl: '//webmetrics.ciostage.accenture.com/',
  id: 2268,
  loadFrameTimeout:60000,
  nfAppID: 16867,
  nfWidgetID_alert: 18202,
  nfWidgetID_toast: 18203,
  nfWidgetID_modal: 18201
};
