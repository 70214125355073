



<rebar-header></rebar-header>
<main id="trs-page-container" class="tra-form-bg">
<section class="project--edition">
    <div class="prompt-text">
        <p>
           Please note: The first time you attempt to translate an entire document/image, you might receive a blocked pop-up notification. 
           To proceed, select ‘Always allow pop-ups from translate.accenture.com’ option and try the translation again. 
           This step will only need to be performed once and you can find additional details on <a [href]="aidUrl" target="_blank" rel="noopener noreferrer">this Job AID</a>.
        </p>
    </div>
    <div class="white-text"></div>
    <div class="text-container">
        <div class="text-header">
            <div class="text-input-container">
                <div class="btn-group btn-group-toggle text-input-group" ngbRadioGroup name="text-input-group"
                    [(ngModel)]="sourceLC">
                    <label ngbButtonLabel class="btn-input" *ngFor="let inputLanguage of inputLanguageGroun">
                        <input ngbButton type="radio" [value]="inputLanguage.value" (click)="OnInputLanguageSelect($event)"> {{ inputLanguage.key }}
                    </label>
                </div>
                <div class="text-input-more">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn show-search-language" [ngClass]="{'ls-more': searchFlag===0 && showSearch}"  ngbDropdownToggle (click)="showSearchLanguages(0)"></button>
                    </div>
                </div>
            </div>
            <div class="text-exchange" ngbTooltip="Functionality coming soon..." placement="bottom">
                <svg  class="exchange" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g/><g><path d="M22,8l-4-4v3H3v2h15v3L22,8z"/><path d="M2,16l4,4v-3h15v-2H6v-3L2,16z"/></g></g></svg>
            </div>
            <div class="text-output-container">
                <div class="btn-group btn-group-toggle text-output-group" ngbRadioGroup name="text-output-group"
                    [(ngModel)]="targetLC">
                    <label ngbButtonLabel class="btn-input" *ngFor="let outputLanguage of outputLanguageGroup">
                        <input ngbButton type="radio" [value]="outputLanguage.value" (click)="OnOutputLanguageSelect($event)"> {{ outputLanguage.key }}
                    </label>
                </div>
                <div class="text-output-more">
                    <div ngbDropdown class="d-inline-block">
                        <button class="btn show-search-language" [ngClass]="{'ls-more': searchFlag===1 && showSearch}" ngbDropdownToggle (click)="showSearchLanguages(1)"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-body" [hidden]="showSearch">
            <div class="text-body-input">
                <div class="text-input-area">
                    <img src="./assets/images/loading.gif" class="translate-loading" [hidden]="!loading" alt="">
                    <svg (click)="textInput='';textOutput=[]" *ngIf="textInput !== ''"class="clear-text" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="14" height="14" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M2 30 L30 2 M30 30 L2 2" />
                    </svg>
                    <textarea id="source" maxlength="5000" class="textarea" [(ngModel)]="textInput" autocapitalize="off" autocomplete="off"
                    placeholder="Please input text for translating" *ngIf="files.length === 0"></textarea>
                    <div class="document-input" *ngIf="files.length !== 0">
                        <img src="./assets/images/file.png" class="document-icon" alt="">
                        <span class="document-name">{{ files[0].name }} </span>
                        <span class="space"></span>
                        <svg (click)="remove()" id="i-close" class="remove-document" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="24" height="24" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                            <path d="M2 30 L30 2 M30 30 L2 2" />
                        </svg>
                    </div>
                </div>
                <div class="text-input-status">
                    <div class="tooltip-wrapper" tooltipClass="custom-class" ngbTooltip="This translation is intended for internal use only and is not approved to be shared externally. If you need to share this translation externally, you need to certify it first with an official translation service." placement="bottom">
                        <div class="translate">
                            <button type="button" [disabled]="sourceLC === targetLC || textInput.trim().length > 5000 || (!textInput.trim() && files.length === 0)" class="btn btn-primary btn-sm" (click)="translate($event)">Translate</button>
                        </div>
                    </div>
                    <ngx-dropzone
                        #dropzone
                        class="custom-dropzone"
                        [multiple]="false"
                        [accept]="'.txt, .docx, .pdf, .png, .jpg, .jpeg'"
                        [maxFileSize] = "1024*1024*8"
                        (change)="onFilesAdded($event)"
                    >
                    <ngx-dropzone-label><svg xmlns="http://www.w3.org/2000/svg" class="upload-icon"  height="24" tooltipClass="custom-class" ngbTooltip="Upload a .txt, .docx, .pdf, .png, .jpg, or .jpeg. Maximum file size of 8MB. Files exceeding 8MB will not be translated. For further help, visit the Translation Support Site." placement="bottom"  viewBox="0 0 24 24" width="24">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/>
                    </svg></ngx-dropzone-label>
                    </ngx-dropzone>

                    <div class="speech-container"  *ngIf="!isPlay">
                        <svg (click)="play()" [ngbTooltip]="speechToTextTooltip()" placement="bottom" [disableTooltip]="enableSpeechToText()" [ngClass]="{'audio-disabled': !enableSpeechToText()}" class="text-microphone text-microphone-start" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm-1-9c0-.55.45-1 1-1s1 .45 1 1v6c0 .55-.45 1-1 1s-1-.45-1-1V5zm6 6c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
                        </svg>
                    </div>
                    <div class="speech-speaking-container" *ngIf="isPlay">
                        <span class="speech-border"></span>
                        <svg (click)="stop()" class="text-microphone text-microphone-stop speaking" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm-1-9c0-.55.45-1 1-1s1 .45 1 1v6c0 .55-.45 1-1 1s-1-.45-1-1V5zm6 6c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"/>
                        </svg>
                    </div>
                    <svg *ngIf="textInput.trim()" ngbTooltip="Functionality coming soon..." placement="bottom" class="input-volume" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
                    </svg>
                    <div class="space"></div>
                    <div class="character-count">
                        {{ textInput.length }}/5000
                    </div>
                </div>
            </div>
            <div class="text-body-output">
                <div class="text-output-area">
                    <ng-container *ngIf="!is_html">
                        <p *ngFor="let text of textOutput">{{ text }}</p>
                    </ng-container>
                    <ng-container *ngIf="is_html">
                        <div [innerHtml]="wrapHtml(textOutput[0])"></div>
                    </ng-container>
                </div>
                <div class="text-input-status">
                    <div *ngIf="textOutput.length>0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="translate-download" ngbTooltip="Download translate result" placement="bottom" (click)="download()"  height="24" viewBox="0 0 24 24" width="24">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"/>
                        </svg>
                    </div>
                    <div *ngIf="textOutput.length>0" ngbTooltip="Copied" triggers="click" placement="top" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="translate-copy" ngbTooltip="Copy translate result" placement="bottom" (click)="copy()"  height="24" viewBox="0 0 24 24" width="24">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M16 1H2v16h2V3h12V1zm5 4H6v18h15V5zm-2 16H8V7h11v14z"/>
                        </svg>
                    </div>
                    <svg *ngIf="textOutput.length>0" ngbTooltip="Functionality coming soon..." placement="bottom" class="input-volume" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="translate-search-language" [hidden]="!showSearch">
            <div class="translate-search-bar">
                <input placeholder="Search languages" (input)="searchLanguageInput($event)" #searchLanguage [(ngModel)]="searchLanguageText" type="text" class="translate-search-language-box">
            </div>
            <div class="translate-all-languages language-list-unfiltered-langs-sl_list">
                <div class="language-list-item-wrapper"  *ngFor="let language of languages" (click)="chooseLanguage(language)">
                    <div class="language-list-item">{{ language.key }}</div>                
                </div>
            </div>
        </div>
    </div>
    <div class="status-bar">
        <div class="status" [hidden]="textInput.length <= 5000">
            <img src="./assets/images/warning-circle.png" class="warning-icon" alt=""> <span class="warning-text">Character limit: 5000</span>
        </div>
        <div class="space"></div>
    </div>
    
    <audio style="display: none;" #audio controls autoplay></audio>

</section>
</main>
<rebar-footer></rebar-footer>

<ng-template #content1 let-c1="close" let-d1="dismiss">
    <div class="modal-body">
      <div class="modal-custome-content">
        <h1 class="legal-title">
            Privacy Statement
        </h1>
        <p>
            Please be aware that any personal data that you and others provide through
            this tool may be processed by Accenture.
          </p>
          <p>
            The protection of your personal data is very important to Accenture.
            Accenture is committed to keeping your personal data secure, and
            processing it in accordance with, applicable data protection laws
            and our internal policies, including
            <a
              [href]="countryUrl"
              target="_blank"
              rel="noopener noreferrer"
              >Accenture’s Global Data Privacy Policy 0090.</a
            >
          </p>
          <p>
            Your decision to provide your personal data to Accenture is
            voluntary. However, given that this tool requires personal data to
            function, you will not be able to use this tool if you do not
            provide your personal data.
          </p>
          <p>
            Before providing any personal data through this tool, Accenture
            invites you to carefully read its
            <a
              [href]="dataUrl"
              target="_blank"
              rel="noopener noreferrer"
              >privacy statement</a
            >, which includes important information on why and how Accenture is
            processing your personal data.
          </p>
        <div class="studio-logo">
          <button type="button" ngbAutofocus class="btn btn-primary" (click)="c1('Save click')">I Agree</button>
          <div class="space"></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #warning let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ warningTitle }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="error_body">
            <div class="icon_container">
                <svg *ngIf="warningType === 'error'" class="error_icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z"/>
                </svg>
                <svg *ngIf="warningType === 'warning'" class="warning_icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M1 21h22L12 2 1 21zm3.47-2L12 5.99 19.53 19H4.47zM11 16h2v2h-2zm0-6h2v4h-2z"/>
                </svg>
            </div>
            <div [innerHtml]="wrapHtml(warningMessage)"></div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button ngbAutofocus type="button" class="btn btn-primary" (click)="modal.close('Close click')">Close</button>
    </div>
  </ng-template>

  
<script>
    DD_RUM.init({
      trackInteractions: true,
      actionNameAttribute: 'data-custom-name',
  
    })
  </script>