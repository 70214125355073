import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

    constructor() { }

    isIE() {
        return (!!window['ActiveXObject' as any] || 'ActiveXObject' in window);
    }

    isFireFox() {
        return navigator.userAgent.indexOf('Firefox') > 0;
    }
}
