<!-- Body content -->
<div class="container-fluid p-0">
  <div id="pageNotfound">
    <div class="notfound text-center w-100">
      <div class="notfound-bg">
        <div class="w-100"></div>
        <div class="w-100"></div>
        <div class="w-100"></div>
      </div>
      <h1 class="text-uppercase">oops!</h1>
      <h2>Error 404 : Page Not Found</h2>
      <a class="card-link btn btn-sm btn-outline-primary mt-2 d-inline-block myLearnbtn" href="" data-toggle="tooltip"
      data-placement="top" title="Back Home-page">Go back</a>
    </div>
  </div>
</div>
<!-- End Body content -->