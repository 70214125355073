<main id="trs-page-container" class="tra-form-bg">
<section class="project--edition">
    <div class="fixed">
        <div class="center" style="cursor: pointer;" (click)="open(content)">
            <svg class="download-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
                <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>                       
                <line fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="2.7" y2="14.2"/>    
            </svg>
        </div>
    </div>
    <div class="content-body" id="htmlData" #htmlData>
      <p class="disclaimer">*This translation is intended for internal use only and is not approved to be shared externally. If you need to share this translation externally, you need to certify it first with an official translation service.*</p>
      &nbsp;
        <div class="m-5 text-container" [innerHTML]="translate_f" id="innerHtmlData" #innerHtmlData>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Download Translated Document</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
          <label for="dropdownBasic1">Save File As</label>
          <div class="input-group">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" style="width:100px;" id="dropdownBasic1" ngbDropdownToggle>{{ filetype }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="setFileType('PDF')">PDF</button>
                  <button ngbDropdownItem (click)="setFileType('Docx')">Docx</button>
                  <button ngbDropdownItem (click)="setFileType('Txt')">Txt</button>
                </div>
          </div>
        </div>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click'); download();">Download</button>
    </div>
  </ng-template>

</main>

