import { Component, OnInit } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'rebar-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
  closeResult: any;
  countryUrl =
    'https://policies.accenture.com/Pages/0001-0100/0090.aspx';
  dataUrl =
    'https://in.accenture.com/protectingaccenture/data-security/5422-2/';
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  // Modal
  open(content: any) {
    datadogRum.addAction('trs_AppTerms',{
      language: {
        language: `trs_ClickPrivacyStatement`,
      }
    })
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'studio-modal-translate static-modal'
    });
  }
  // End Modal
}
