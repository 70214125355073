
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './root/app.component';
import { AppConfigService } from './core/services/app-config.service';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { MsalRedirectComponent, MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { RebarAuthModule } from './core/rebarauth/rebar.auth.module';
import { AlertComponent } from './alert/alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { DataService } from './core/services/DataService';
import { RecordService } from './core/services/RecordService';
import { TranslateModule } from './feature/translate/translate.module';
import { TranslateFModule } from './feature/translate_f/translate_f.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';
import { ConsentComponent } from './shared/consent/consent.component';
import { AuthorizationGuard } from './core/services/authorization.guard';
import { CommonService } from './core/services/CommonService';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
//import { DatadogSessionService} from './core/services/datadog-session.service';
import { ToastComponent } from './toast/toast.component';
import { ModalComponent } from './modal/modal.component';





@NgModule({
  declarations: [
    AppComponent,
    AlertComponent, 
    NotAuthorizedComponent,
    ConsentComponent,
    ToastComponent,
    ModalComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],

  imports:[
    BrowserModule, 
    BrowserAnimationsModule, 
    FormsModule, 
    AppRoutingModule, 
    HttpClientModule, 
    RebarAuthModule.forRoot(), 
    MsalModule, 
    NgbModalModule, 
    TranslateModule, 
    TranslateFModule, 
  ],

  providers: [
    DataService,
    RecordService,
    CommonService,
    //DatadogSessionService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
 })
export class AppModule {}
